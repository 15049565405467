export class Video {
  constructor() {
    this.initVideo();
  }

  private initVideo() {
    const videoContainers: NodeListOf<HTMLElement> = document.querySelectorAll('.js-video');

    videoContainers?.forEach((videoContainer: HTMLElement) => {
      const video: HTMLVideoElement = videoContainer.querySelector('video');
      const playButton: HTMLElement = videoContainer.querySelector('.js-video-play');

      playButton?.addEventListener('click', () => {
        videoContainer.classList.add('active');
        video.play();
        video.controls = true;
      }, {passive: true});

      // video.addEventListener('pause', () => {
      //   videoContainer.classList.remove('active');
      //   video.controls = false;
      // }, {passive: true});
    });
  }

}
