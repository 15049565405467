
export class Scroll {
  private lastPosition = 0;

  constructor() {
    this.initScroll();
  }

  private initScroll() {
    const body = document.querySelector('body');
    window.addEventListener('scroll', () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;

      if (st < this.lastPosition) {
        body.classList.remove('down');
      } else {
        body.classList.add('down');
      }
      this.lastPosition = st <= 0 ? 0 : st;
    }, {passive: true});
  }
}
