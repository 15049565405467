import Swiper, { Navigation, Pagination, Autoplay, FreeMode } from 'swiper';

export class Slider {
  public constructor (){
    this.initSlider();
  }

  private initSlider(): void {

    const swiper = new Swiper('.c-slider', {
      loop: false,
      modules: [Navigation, Pagination],
      spaceBetween: 144,
      slidesPerView: 'auto',
      centeredSlides: true,
      pagination: {
        el: '.c-slider__pagination',
        clickable: true,
      },

      navigation: {
        nextEl: '.c-slider__button--next',
        prevEl: '.c-slider__button--prev',
      },
    });

    swiper;
  }
}
