
export class Apply {
  constructor() {
    const allContents: NodeListOf<HTMLElement> = document.querySelectorAll(`[data-content-type]`);
    const buttons: NodeListOf<HTMLElement> = document.querySelectorAll('[data-button-type]');
    this.addDnone(allContents);
    this.addDnone(buttons);
    this.initButtons(allContents);
    this.getUrl();
  }

  private initButtons(allContents: NodeListOf<HTMLElement>) {
    const buttons: NodeListOf<HTMLElement> = document.querySelectorAll('.js-apply-button');

    buttons?.forEach((button: HTMLElement) => {
      button.addEventListener('click', () => {
        const type = button.dataset.type;
        const contents: NodeListOf<HTMLElement> = document.querySelectorAll(`[data-content-type="${type}"]`);
        this.addDnone(allContents);

        if (contents) {
          this.setActive(contents);
        }
      }, {passive: true});
    });
  }

  private addDnone(contents: NodeListOf<HTMLElement>) {
    contents?.forEach((content: HTMLElement) => {
      content.classList.add('d-none');
    });
  }

  private getUrl() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get('content');

    const contents: NodeListOf<HTMLElement> = document.querySelectorAll(`[data-content-type="${type}"]`);
    const buttons: NodeListOf<HTMLElement> = document.querySelectorAll('.js-apply-button');
    const text: NodeListOf<HTMLElement> = document.querySelectorAll('[data-text-type]');
    const buttonsType: NodeListOf<HTMLElement> = document.querySelectorAll(`[data-button-type="${type}"]`);

    if (contents.length != 0) {
      this.addDnone(buttons);
      this.addDnone(text);
      this.setActive(contents);
      this.setActive(buttonsType);
    }
  }

  private setActive(contents: NodeListOf<HTMLElement>) {
    contents?.forEach((content: HTMLElement) => {
      content.classList.remove('d-none');
    });
  }
}
