import { Apply } from "./apply";
import { Scroll } from "./scroll";
import { Slider } from "./slider";
import { Video } from "./video";

export class Main {
  constructor() {
    new Slider();
    new Video();
    new Apply();
    new Scroll();
  }
}
